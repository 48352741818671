<template>
  <div v-if="!loading">
    <h1 class="main-header">
      <translate translate-context="price_list_template" :translate-params="{id: priceList.id, name: priceList.display_name}">Edit template  #%{id} - %{name}</translate>
    </h1>
    <Form :data="priceList" @save="onSave" :save_text="$pgettext('form','Save')"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Form from "@/domain/priceListTemplate/components/Form.vue";
import {RecordNotFound, SuccessNotification} from "@/domain/core/exception/exceptions";

export default {
  name: 'EditPriceList',
  components: {Form},
  data() {
    return {
      loading: true,
    }
  },
  async mounted() {
    try {
      await this.fetchPriceList(this.$route.params.id)
    } catch (e) {
      if (e instanceof RecordNotFound) {
        await this.$router.push({name: 'priceListTemplate.list'})
      }
    }
    this.loading = false
  },
  computed: {
    ...mapGetters('priceListTemplate', [
      'priceList',
    ]),
  },
  methods: {
    ...mapActions('priceListTemplate', [
      'fetchPriceList',
      'updatePriceList',
    ]),
    async onSave(data) {
      await this.updatePriceList({id: this.priceList.id,data: data})
      await this.$router.push({name: 'priceListTemplate.list'})
      throw new SuccessNotification(this.$pgettext('price_list_template', 'Price List Template updated!'))
    }
  }
}
</script>