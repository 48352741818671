<template>
  <div class="mb-3">
    This Gallery doesn't have any price list, it will use the parent gallery price list.<br>
    Media in this gallery count in the parent cart
  </div>
</template>

<script>
export default {
  name: "ParentPriceList",
  emits: ['update'],
  data() {
    return {
      configuration: {}
    }
  },
  methods: {
    init({configuration, onChange}) {
      if (!onChange) return
      this.configuration = configuration
      this.$emit('update', {
        items: [],
        configuration: this.configuration
      })
    },
    getPriceList() {
      return {
        items: [],
        configuration: this.configuration
      }
    },
  }
}
</script>