<template>
  <CCard>
    <CCardHeader>
      <div class="card-header-actions">
        <CButton variant="outline" color="success" @click="onReset" ref="reset-button" v-translate
                 translate-context="form" class="mr-2">Reset
        </CButton>
        <CButton color="primary" @click="onSave" ref="save-button">{{ save_text }}</CButton>
      </div>
    </CCardHeader>
    <CCardBody class="p-0">
      <CContainer fluid class="grid-stripped">
        <PFRow :label="$pgettext('price_list_template.label', 'Template name')">
          <CInput v-model="form.display_name" :placeholder="$pgettext('price_list_template.placeholder','Name')"/>
        </PFRow>
        <PFRow :label="$pgettext('price_list_template.label', 'Currency')">
          <CurrencySelect v-model="form.currency"/>
        </PFRow>
        <PFRow :label="$pgettext('price_list_template.label', 'Price list type')">
          <PSelectKeyValue :options="priceListOptions" :value="form.configuration.type" @input="onChangeLayoutType"/>
        </PFRow>
        <PFRow :label="$pgettext('price_list_template.label', 'Prices')">
          <PackPriceList
              :currency="form.currency"
              @update="onUpdatePrice"
              ref="prices"
              v-if="form.configuration.type === priceListType.pack"
          />
          <UnitPriceList
              :currency="form.currency"
              @update="onUpdatePrice"
              ref="prices"
              v-if="form.configuration.type === priceListType.unit"
          />
          <CustomPriceList
              :currency="form.currency"
              @update="onUpdatePrice"
              ref="prices"
              v-if="form.configuration.type === priceListType.custom"
          />
        </PFRow>


      </CContainer>
    </CCardBody>
  </CCard>

</template>

<script>
import PFRow from "@/domain/core/components/PFRow.vue";
import CurrencySelect from "@/domain/core/components/CurrencySelect.vue";
import ParentPriceList from "@/domain/eventGallery/tab/priceList/ParentPriceList.vue";
import UnitPriceList from "@/domain/eventGallery/tab/priceList/UnitPriceList.vue";
import PackPriceList from "@/domain/eventGallery/tab/priceList/PackPriceList.vue";
import CustomPriceList from "@/domain/eventGallery/tab/priceList/CustomPriceList.vue";
import priceListType from "@/domain/core/constant/priceListType";
import PSelectKeyValue from "@/domain/core/components/PSelectKeyValue.vue";

export default {
  name: "Form",
  components: {PSelectKeyValue, CustomPriceList, PackPriceList, UnitPriceList, ParentPriceList, CurrencySelect, PFRow},
  props: {
    data: {
      type: Object,
      required: true
    },
    save_text: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      form: {
        display_name: '',
        currency: null,
        items: [],
        configuration: {}
      },
    }
  },
  async mounted() {
    this.onReset()
  },
  computed: {
    priceListType() {
      return priceListType
    },
    priceListOptions() {
      return priceListType.options(true, false)
    }
  },
  emits: ['save'],
  methods: {
    onReset() {
      this.form = {...this.data}
      this.initPrices(false)
    },
    async onSave() {
      this.$emit('save', this.form)
    },
    onUpdatePrice({items, configuration}) {
      this.form.items = items
      this.form.configuration = {
        ...configuration,
        type: this.form.configuration.type
      }
    },
    async onChangeLayoutType(newTypeObj) {
      const newType = newTypeObj.value
      if (newType === priceListType.custom) {
        this.form.configuration = {
          type: newType
        }
        return await this.initPrices(true)
      }
      let confirmMsg = this.$gettext('Changing the layout will reset every unsaved parameters, are you sure?')
      let isEmpty = !this.form.items || this.form.items.length === 0
      if (isEmpty || confirm(confirmMsg)) {
        this.form.items = []
        this.form.configuration = {
          type: newType
        }
        return await this.initPrices(true)
      }
    },
    async initPrices(onChange = false) {
      await this.$nextTick()
      await this.delay(100)
      if (!this.$refs.prices) return
      this.$refs.prices.init({
        items: this.form.items,
        configuration: this.form.configuration,
        onChange: onChange
      })
    }
  }
}
</script>